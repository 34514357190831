<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="IdentificationDocs"
      :page.sync="page"
      :items-per-page="identificationDocsPerPage"
      :server-items-length="totalIdentificationDocs"
      hide-default-footer
      @page-count="pageCount = $event"
      sort-by="name"
      class="elevation-1 ml-4"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Identification Document</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="700px" persistent>
            <template v-slot:activator="{ on }">
              <v-btn color="green" dark class="mb-2" v-on="on" v-if="showCreate">Add Document</v-btn>
            </template>
            <v-card>
              <validation-observer ref="form">
                  <form @submit.prevent="onSubmit">
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        name="Identification Document Name"
                      >
                      <v-text-field
                        v-model="editedIdentificationDoc.name"
                        label="Name"
                        class="mt-n6"
                        outlined
                        dense
                        name="Identification Document Name"
                        :error-messages="errors"
                        color="grey"
                        filled
                      />
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-btn class="success ml-4 mb-4" type="submit" text>Save</v-btn>
                <v-btn class="mb-4" color="blue darken-1" text @click="close">Cancel</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
              </form>
              </validation-observer>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editIdentificationDoc(item)"
          v-if="showEdit"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="identificationDocDialog(item)"
          v-if="showDelete"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="snackbar"
      top
      :color="color"
      :timeout="timeout"
    >
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          icon
          v-bind="attrs"
          @click="setSnackbar(false)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="deleteDialog" hide-overlay max-width="500px">
    <v-card tile outlined>
        <v-card-text>
          <p>Are you sure you want to delete this Document ?</p>
          <v-btn link small color="blue" @click="deleteIdentificationDoc">Yes</v-btn>
          <v-btn link small color="blue" @click="deleteDialog = false">No</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div class="pt-2 px-12">
      <v-row>
        <v-col cols="12" sm="2">
          <v-select
            :items="selectIdentificationDocsPerPage"
            v-model="identificationDocsPerPage"
            label="Identification Documents per page"
            dense
            filled
          ></v-select>
        </v-col>
        <v-col cols="12" sm="10">
          <v-pagination
            v-model="page"
            :length="pageCount"
            circle
            total-visible="7"
            color="blue"
          ></v-pagination>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import IdentificationDoc from '@/models/DocumentType'
export default {
  data: () => ({
    privileges: null,
    showEdit: false,
    showDelete: false,
    showCreate: false,
    dialog: false,
    deleteDialog: false,
    identificationDocIndex: null,
    identificationDocItem: null,
    headers: [
      {
        text: 'Names',
        align: 'start',
        sortable: false,
        value: 'name'
      },
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    IdentificationDocs: [],
    editedIndex: -1,
    editedIdentificationDoc: new IdentificationDoc(),
    defaultItem: new IdentificationDoc(),
    selectIdentificationDocsPerPage: [2, 5, 10, 15, 20, 25, 30],
    page: 1,
    pageCount: 0,
    identificationDocsPerPage: 20,
    totalIdentificationDocs: 0
  }),

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'New Document' : 'Edit Document'
    },
    ...mapState('store', ['snackbar', 'color', 'text', 'timeout', 'currentUser'])
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    page: {
      handler () {
        this.initialize()
      }
    },
    identificationDocsPerPage: {
      handler () {
        this.page = 1
        this.initialize()
      }
    }
  },
  mounted () {
    this.privileges = this.currentUser.privileges
    this.checkPrivileges()
  },

  created () {
    this.initialize()
  },
  destroyed () {
    this.setSnackbar(false)
  },

  methods: {
    ...mapMutations('store', {
      setSnackbar: 'SET_SNACKBAR',
      setColor: 'SET_COLOR',
      setText: 'SET_TEXT'
    }),
    initialize () {
      const pageNumber = this.page - 1
      this.$delinquentService.fetchAllDocumentType(pageNumber, this.identificationDocsPerPage)
        .then(response => {
          if (!response.status) {
            this.IdentificationDocs = response.content
            this.totalIdentificationDocs = response.totalElements
          } else {
            this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },

    editIdentificationDoc (item) {
      this.editedIndex = this.IdentificationDocs.indexOf(item)
      this.$delinquentService.fetchSingleDocumentType(item.id)
        .then(response => {
          if (response.id) {
            this.editedIdentificationDoc = response
          } else {
            this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
      this.dialog = true
    },
    identificationDocDialog (item) {
      this.identificationDocIndex = this.IdentificationDocs.indexOf(item)
      this.identificationDocItem = item
      this.deleteDialog = true
    },

    deleteIdentificationDoc () {
      this.deleteDialog = false
      this.$delinquentService.deleteDocumentType(this.identificationDocItem.id)
        .then(response => {
          if (response.status === 'success') {
            this.IdentificationDocs.splice(this.identificationDocIndex, 1)
          } else {
            this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },

    close () {
      this.dialog = false
      setTimeout(() => {
        this.editedIdentificationDoc = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },

    onSubmit () {
      this.$refs.form.validate().then(success => {
        if (!success) {
          return
        }
        if (this.editedIndex > -1) {
          this.updateIdentificationDoc()
        } else this.createIdentificationDoc()
        this.$nextTick(() => {
          this.$refs.form.reset()
        })
      })
    },
    updateIdentificationDoc () {
      const deletedIdentificationDocArray = this.IdentificationDocs.splice(this.editedIndex, 1)
      this.$delinquentService.saveDocumentType(this.editedIdentificationDoc)
        .then(response => {
          if (response.id) {
            this.IdentificationDocs.splice(this.editedIndex, 1, response)
            this.close()
          } else {
            this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.IdentificationDocs.splice(this.editedIndex, 0, deletedIdentificationDocArray[0])
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },
    createIdentificationDoc () {
      this.$delinquentService.saveDocumentType(this.editedIdentificationDoc)
        .then(response => {
          if (response.id) {
            this.IdentificationDocs.push(response)
            this.close()
          } else {
            this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },
    checkPrivileges () {
      this.privileges.indexOf('CREATE_DOCUMENTTYPES') > -1 || this.privileges.indexOf('ALL_AUTHORITIES') > -1 ? this.showCreate = true : this.showCreate = false
      this.privileges.indexOf('UPDATE_DOCUMENTTYPES') > -1 || this.privileges.indexOf('ALL_AUTHORITIES') > -1 ? this.showEdit = true : this.showEdit = false
      this.privileges.indexOf('DELETE_DOCUMENTTYPES') > -1 || this.privileges.indexOf('ALL_AUTHORITIES') > -1 ? this.showDelete = true : this.showDelete = false
    }
  }
}
</script>
